<!-- 工时管理 > 工时统计 > 项目统计(组件) -->
<template>
  <div :class="[getJump == 'tbgs' ? 'fullScreen' : 'mainList_body']">
    <Crumbs v-if="getJump == 'tbgs'">
      <el-breadcrumb-item slot="after">
        <span class="close_btn" @click="re"> 项目统计<i class="el-icon-close"></i> </span>
      </el-breadcrumb-item>
    </Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <el-select
            v-model="filter.condition.projectName"
            class="ipt_width"
            placeholder="项目名称"
            clearable
          >
            <el-option
              v-for="item in projectData"
              :key="item.id"
              :label="item.projectName"
              :value="item.projectName"
            >
            </el-option>
          </el-select>
          <el-button type="primary" class="btn_teal" icon="el-icon-search" @click="handleSearch"
            >查询</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            style="width: 100%"
            border
            :data="tableData"
            :header-cell-style="{ background: '#F4F4F4', textAlign: 'center' }"
          >
            <el-table-column
              prop="projectName"
              label="项目名称"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-link type="primary" @click="handleprojectName(scope.row.projectId)">{{
                  scope.row.projectName
                }}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="endDate" label="计划完成日期" align="center" width="250">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.endDate | dateFormat }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="projectSumHour"
              label="项目投入总工时"
              align="center"
              width="250"
            ></el-table-column>
            <el-table-column
              prop="userSumHour"
              label="个人投入工时"
              align="center"
              width="250"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 项目工时统计(弹出框) -->
    <StatisticsDlg
      v-if="isStatisticsDlg"
      :isDetailedDlg="isStatisticsDlg"
      :getProjectId="projectId"
      @childEvent="callEventStatistics"
    >
    </StatisticsDlg>
    <!-- 项目工时统计(弹出框) -->
  </div>
</template>

<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    StatisticsDlg: () => import('../dlg/StatisticsDlg.vue'),
  },
  inject: ['re'],
  props: {
    isProjectStatisticsDlg: {
      type: Boolean,
      default: false,
    },
    getJump: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false, // 表格 -- 加载 变量
      projectData: [], // 项目名称 下拉列表数据
      // 列表 查询对象
      filter: {
        condition: {
          projectName: '',
        },
      },
      tableData: [], // 表格 列表数据
      projectId: 0, // 项目 id
      isStatisticsDlg: false, // 是否 "显示" 或 "隐藏" 项目工时统计 组件
    }
  },
  mounted() {
    /** 加载 "项目名称" 下拉列表数据 **/
    this.getProjectData()

    /** 加载 "项目统计" 列表数据 **/
    this.getProjectStatisticsListData()
  },
  methods: {
    /** 加载 "项目名称" 下拉列表数据 **/
    getProjectData() {
      let _param = {
        condition: {
          projectName: '',
        },
        pageNow: 1,
        pageSize: 1000,
      }
      this.$api.hour
        .listProjectToManhour(_param)
        .then(res => {
          this.projectData = res.data.records
        })
        .catch(err => {
          console.log(err)
        })
    },

    /** 加载 "项目统计" 列表数据 **/
    getProjectStatisticsListData() {
      this.loading = true
      this.$api.hour
        .getAllMahhoursByProject(this.filter.condition)
        .then(res => {
          this.tableData = res.data
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /** 点击 "查询" 按钮 **/
    handleSearch() {
      /** 加载 "项目统计" 列表数据 **/
      this.getProjectStatisticsListData()
    },

    /**
     * 点击 "列表 -- 项目名称"
     * @param projectId     项目id
     */
    handleprojectName(projectId) {
      this.isStatisticsDlg = true
      this.projectId = projectId
    },

    /**
     * 调用 “项目工时统计” 组件
     * @param  data    子组件 传过来的值
     */
    callEventStatistics(data) {
      this.isStatisticsDlg = data
    },
  },
}
</script>

<style lang="scss" scoped>
.close_btn {
  position: absolute;
  top: 10px;
  display: inline-block;
  color: #ffffff;
  background-color: #014d67;
  padding: 5px 12px 5px 16px;
  margin-left: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.close_btn:hover {
  cursor: pointer;
}

.mainList_body {
  height: calc(100% - 45px);
}
</style>
